import Vue from 'vue';
import IConfig from '@/plugins/configloader/IConfig';

declare module 'vue/types/vue' {
	interface VueConstructor {
		$appConfig: IConfig;
	}
}

export interface Settings {
	config: IConfig;
}

export default class ConfigLoader {

	static install(vue: typeof Vue, options: Settings) {
		vue.$appConfig = options.config;
	}

}
