<template>
	<div class="tryLaterPage">
		<div class="header"><img src="/images/logo.png"/></div>
		<div class="body">
			<img src="images/trylater.png"/>
			<span>Unfortunately, all the places in the store are occupied and you<br/> cannot continue this survey.<br/>Sorry for the inconvenience.</span>
			<span>Leider sind alle Plätze im Laden belegt und Sie können diese<br/> Umfrage nicht fortsetzen.<br/>Entschuldigung für die Unannehmlichkeit.</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'TryLaterPage'
};
</script>

<style scoped>

.tryLaterPage {
	position: fixed;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: #0D161E;
	display: flex;
	flex-direction: column;
	padding: 1rem;
}

.header img {
	height: 2rem;
}

.body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	justify-items: center;
	align-items: center;
	font-size: 2.55rem;
	color: white;
	flex: 1 1 auto;
}

.body > img {
	height: 23rem;
}

.body > span {
	text-align: center;
}

.body > * {
	margin-bottom: 2rem;
}

.body > span:last-child {
}

</style>
