//PRODUCTION
/* export default class Config {
  static backendAddress: string =
    "https://pixelstreaming-backend-prod.studio-invrsion.com";
  static instanceProtocol: string = "https";
  static instancePort: number = 443;
  static instanceWebSocketProtocol: string = "wss";
} */

// STAGING
// export default class Config {

//     static backendAddress: string = 'https://pixelstreaming-backend-stg.studio-invrsion.com'
//     static instanceProtocol: string = 'https';
//     static instancePort: number = 443;
//     static instanceWebSocketProtocol: string = 'wss';

// }

// DEVELOPMENT
export default class Config {
  static backendAddress: string =
    "https://pixelstreaming-backend-dev.studio-invrsion.com";
  static instanceProtocol: string = "https";
  static instancePort: number = 443;
  static instanceWebSocketProtocol: string = "wss";
}

//LOCAL
// export default class Config {

//     static backendAddress: string = 'http://localhost:3000'
//     static instanceProtocol: string = 'http';
//     static instancePort: number = 443;
//     static instanceWebSocketProtocol: string = 'ws';

// }
