import { Event } from "@/plugins/pixelstreamer/Event";
import IPixelStreamer from "@/plugins/pixelstreamer/IPixelStreamer";
import { CompletionReason } from "@/plugins/api/model/CompletionReason";

export default class PixelStreamer implements IPixelStreamer {
  subscribers: { [key: string]: ((...args: any[]) => void)[] };
  index: number = 0;
  hasBeenConnectedOnce: boolean = false;

  constructor() {
    this.subscribers = {};
    for (const event in Event) {
      this.subscribers[event] = [];
    }
  }

  on(event: Event, callback: (...args: any[]) => void): void {
    this.subscribers[event].push(callback);
  }

  setInstanceAddress(address: string) {
    const eventSource = new EventSource(`${address}/events`);
    (eventSource as any).index = this.index;
    ++this.index;
    eventSource.onmessage = (event) => {
      console.log("ws event ====>", event);
      const data = JSON.parse(event.data);
      if (data.type === "ExperienceCompleted")
        this.notifyExperienceCompleted(data.reason);
    };
  }

  notifyConnectionEstablished(): void {
    this.subscribers[Event.connectionEstablished].forEach((subscriber) =>
      subscriber()
    );
  }

  notifyConnectionLost(): void {
    this.subscribers[Event.connectionLost].forEach((subscriber) =>
      subscriber()
    );
  }

  notifyExperienceCompleted(reason: CompletionReason): void {
    this.subscribers[Event.experienceCompleted].forEach((subscriber) =>
      subscriber(reason)
    );
  }
}
