import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './utils/Promise.ts';

import ComponentResolver from '@/plugins/componentresolver/ComponentResolver';
import API from '@/plugins/api/API';
import PixelStreamerPlugin from '@/plugins/pixelstreamer/PixelStreamerPlugin';
import ConfigLoader from '@/plugins/configloader/ConfigLoader';
import Config from '@/config';

Vue.use(ComponentResolver);
Vue.use(API, {hostAddress: Config.backendAddress});
Vue.use(PixelStreamerPlugin);
Vue.use(ConfigLoader, {config: Config});

Vue.config.productionTip = false;

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');
