<template>
	<div class="experienceCompletedPage">
		<div class="header"><img src="/images/logo.png"/></div>
		<div class="body">
			<span>This experience has already been completed</span>
			<span>{{ completionReason }}</span>
		</div>
	</div>
</template>

<script>
import {CompletionReason} from '@/plugins/api/model/CompletionReason';

export default {
	name: 'ExperienceCompletedPage',
	props: {
		reason: CompletionReason
	},
	computed: {
		completionReason() {
			switch (this.reason) {
				case CompletionReason.UserCompleted:
					return 'Everything went well';
				case CompletionReason.UserInactivity:
					return 'No one was connected for a while';
				case CompletionReason.TimeoutReached:
					return 'The experience lasted too long';
			}
		}
	}
};
</script>

<style scoped>

.experienceCompletedPage {
	position: fixed;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: #0D161E;
	display: flex;
	flex-direction: column;
	padding: 1rem;
}

.header img {
	height: 2rem;
}

.body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 3.4rem;
	color: white;
	flex: 1 1 auto;
}

.body > img {
	height: 23rem;
}

.body > * {
	margin-bottom: 2rem;
}

.body > span:last-child {
	font-size: 1.7rem;
}

</style>
