import Vue from 'vue';
import IPixelStreamer from '@/plugins/pixelstreamer/IPixelStreamer';
import PixelStreamer from '@/plugins/pixelstreamer/PixelStreamer';

declare module 'vue/types/vue' {
    interface Vue {
        $pixelStreamer: IPixelStreamer;
    }
}

declare global {
    interface Window {
        pixelStreamer: IPixelStreamer;
    }
}

export default class PixelStreamerPlugin {

    static install(vue: typeof Vue, options?: object) {
        window.pixelStreamer = vue.prototype.$pixelStreamer =  new PixelStreamer();
    }

}
