<template>
	<div class="disconnectionPage">
		<div class="header"><img src="/images/logo.png"/></div>
		<div class="body">
			<img src="images/disconnected.png"/>
			<span>Trying to reconnect...</span>
			<span>This may take a few minutes</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'DisconnectionPage'
};
</script>

<style scoped>

.disconnectionPage {
	position: fixed;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: #0D161E;
	display: flex;
	flex-direction: column;
	padding: 1rem;
}

.header img {
	height: 2rem;
}

.body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 3.4rem;
	color: white;
	flex: 1 1 auto;
}

.body > img {
	height: 23rem;
}

.body > * {
	margin-bottom: 2rem;
}

.body > span:last-child {
	font-size: 1.7rem;
}

</style>
