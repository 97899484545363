declare global {
    interface PromiseConstructor {
        withRetry<T>(predicate: () => Promise<T>, maxAttempts: number): Promise<T>

        poll<T>(predicate: () => Promise<T>, delay: number, maxAttempts: number): Promise<T>
    }
}

Promise.withRetry = function <T>(predicate: () => Promise<T>, maxAttempts: number): Promise<T> {
    return new Promise((resolve, reject) => {
        predicate()
            .then(result => resolve(result))
            .catch(exception => {
                if (maxAttempts <= 1)
                    reject(exception);
                else
                    Promise.withRetry(predicate, maxAttempts - 1)
                        .then(result => resolve(result))
                        .catch(exception => reject(exception));
            });
    });
};

Promise.poll = function <T>(predicate: () => Promise<T>, delay: number, maxAttempts: number): Promise<T> {
    return new Promise((resolve, reject) => {
        predicate()
            .then(result => resolve(result))
            .catch(exception => {
                if (maxAttempts <= 1)
                    reject(exception);
                else
                    setTimeout(() => {
                        Promise.poll(predicate, delay, maxAttempts - 1)
                            .then(result => resolve(result))
                            .catch(exception => reject(exception));
                    }, delay);
            });
    });
};

export {};
