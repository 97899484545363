














import Vue from 'vue';

export default Vue.extend({
	name: 'Loading',
	data() {
		return {
			index: 0,
			tips: [
				'Building the store...',
				'Painting the walls...',
				'Turning the lights on...',
				'Aligning the shelves...',
				'Placing products on shelves...'
			],
			animationInterval: 0
		};
	},
	mounted() {
		this.animationInterval = setInterval(() => {
			this.index = (this.index + 1) % this.tips.length;
		}, 5000);
	},
	beforeDestroy() {
		clearInterval(this.animationInterval);
	}
});
