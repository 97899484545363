import Vue from 'vue';
import IPixelStreamingAPI from '@/plugins/api/IPixelStreamingAPI';
import RestPixelStreamingAPI from '@/plugins/api/RestPixelStreamingAPI';

declare module 'vue/types/vue' {
    interface Vue {
        $api: IPixelStreamingAPI;
    }
}

export interface Settings {

    hostAddress: string;

}

export default class API {

    static install(vue: typeof Vue, options: Settings) {
        vue.prototype.$api = new RestPixelStreamingAPI(options.hostAddress);
    }

}
