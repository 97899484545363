




import Vue from 'vue';
import InnerFe from '@/components/InnerFe.vue';
import Home from '@/views/Home.vue';

export default Vue.extend({
	name: 'Wrapper',
	components: {
		'inner-fe': () => Vue.$componentResolver.resolveComponent('inner-fe')
	},
	props: {
		instanceAddress: String
	},
});
