










import Vue from 'vue';
import Wrapper from '@/views/Wrapper.vue';
import Loading from '@/views/Loading.vue';
import DisconnectionPage from '@/views/DisconnectionPage.vue';
import TryLaterPage from '@/views/TryLaterPage.vue';
import {Event} from '@/plugins/pixelstreamer/Event';
import PixelExperience from '@/plugins/api/model/PixelExperience';
import {PixelExperienceState} from '@/plugins/api/model/PixelExperienceState';
import ExperienceCompletedPage from '@/views/ExperienceCompletedPage.vue';
import {CompletionReason} from '@/plugins/api/model/CompletionReason';

export default Vue.extend({
	name: 'Home',
	components: {ExperienceCompletedPage, DisconnectionPage, TryLaterPage, Loading, Wrapper},
	props: {
		pixelExperienceId: String
	},
	data(): {
		waitingForInnerFrontend: boolean,
		loading: boolean,
		completed: boolean,
		tryLater: boolean,
		connectionError: boolean,
		instanceHost?: string,
		pixelExperience?: PixelExperience
	} {
		return {
			waitingForInnerFrontend: true,
			loading: true,
			completed: false,
			tryLater: false,
			connectionError: false,
			instanceHost: undefined,
			pixelExperience: undefined
		};
	},
	mounted() {
		//Try to access to the pixel experience
		this.$api.findPixelExperience(this.pixelExperienceId)
			.then(({pixelExperience}) => {
				this.pixelExperience = pixelExperience;
				console.log("=>", pixelExperience)
				if (pixelExperience.state === PixelExperienceState.New) {
					//Start the pixel experience and access it on response
					this.$api.startPixelExperience(this.pixelExperienceId)
						.then(response => this.accessPixelExperience(response.instanceHost))
						//If there is some error accessing the pixel experience it's necessary to retry later
						.catch(error => {
							if (error.response) this.tryLater = true;
							else console.error('Unable to start the pixel experience due to', error);
						});
				} else if (pixelExperience.state === PixelExperienceState.Started) {
					//Access directly to the pixel experience
					if (pixelExperience.instanceHost)
						this.accessPixelExperience(pixelExperience.instanceHost);
				} else if (pixelExperience.state === PixelExperienceState.Completed) {
					//Show an error
					this.completed = true;
				}
			});
		this.$pixelStreamer.on(Event.connectionEstablished, () => this.connectionError = this.loading = false);
		this.$pixelStreamer.on(Event.connectionLost, () => this.connectionError = true);
		this.$pixelStreamer.on(Event.experienceCompleted, (reason: CompletionReason) => {
			if (reason !== CompletionReason.UserInactivity && this.pixelExperience?.settings.endExperienceLink)
				window.location.href = this.pixelExperience?.settings.endExperienceLink;
      else if (reason === CompletionReason.UserInactivity && !this.pixelExperience?.settings.failedEndExperienceLink)
        window.location.href = "https://www.duckduckgo.com"
			else if (this.pixelExperience?.settings.failedEndExperienceLink)
				window.location.href = this.pixelExperience?.settings.failedEndExperienceLink;
		});
	},
	methods: {
		accessPixelExperience(instanceHost: string) {
			this.instanceHost = instanceHost;
			const address = `${Vue.$appConfig.instanceProtocol}://${this.instanceHost}:${Vue.$appConfig.instancePort}`;
			this.$pixelStreamer.setInstanceAddress(address);
			//Retry until it's correctly connected
			Promise
				.poll(() => this.$componentResolver.addResolutionUrl(`${address}/inner-fe.js`, `${address}/inner-fe.css`), 5000, 10)
				.catch(error => this.connectionError = true)
				.then(() => this.waitingForInnerFrontend = false);
		}
	}
});
