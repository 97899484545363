import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/home',
        name: 'Home',
        component: Home,
        props: route => route.query
    }
];

const router = new VueRouter({
    routes
});

export default router;
