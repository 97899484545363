import PixelExperience from '@/plugins/api/model/PixelExperience';

export default class FindPixelExperienceResponse {

    pixelExperience: PixelExperience;

    constructor(pixelExperience: PixelExperience) {
        this.pixelExperience = pixelExperience;
    }

}
