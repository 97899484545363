import Vue, {VueConstructor} from 'vue';

export type ResolutionUrls = string[];

export interface Settings {

    defaultResolutionUrls: ResolutionUrls;

}

declare module 'vue/types/vue' {
    interface Vue {
        $componentResolver: ComponentResolver;
    }

    interface VueConstructor {
        $componentResolver: ComponentResolver;
    }
}

export default class ComponentResolver {

    resolutionUrls: ResolutionUrls;

    constructor(defaultResolutionUrls: ResolutionUrls) {
        this.resolutionUrls = defaultResolutionUrls;
    }

    addResolutionUrl(url: string, styleUrl: string | null = null): Promise<void> {
        this.resolutionUrls.push(url);
        return new Promise<void>((resolve, reject) => {
            let style: HTMLLinkElement | null = null;
            if (styleUrl) {
                style = document.createElement('link');
                style.rel = 'stylesheet';
                style.href = styleUrl;
            }
            const script = document.createElement('script');
            script.onload = () => resolve();
            script.onerror = () => {
                this.resolutionUrls = this.resolutionUrls.filter(resolutionUrl => resolutionUrl !== url);
                document.head.removeChild(script);
                if(style) document.head.removeChild(style);
                reject(new Error('Unable to load component files definition'));
            };
            script.type = 'text/javascript';
            script.src = url;
            document.head.append(script);
            if(style) document.head.append(style);
        });
    }

    resolveComponent(name: string): Promise<VueConstructor> {
        const component = Vue.component(name);
        if (component) return Promise.resolve(component);
        return Promise.reject(new Error('Component not found'));
    }

    static install(vue: typeof Vue, options?: Settings): void {
        (window as any).Vue = vue;
        vue.$componentResolver = vue.prototype.$componentResolver = new ComponentResolver(options?.defaultResolutionUrls ?? []);
    }

}
