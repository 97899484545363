import IPixelStreamingAPI from '@/plugins/api/IPixelStreamingAPI';
import axios, {AxiosInstance} from 'axios';
import StartPixelExperienceResponse from '@/plugins/api/responses/StartPixelExperienceResponse';
import FindPixelExperienceResponse from '@/plugins/api/responses/FindPixelExperienceResponse';

export default class RestPixelStreamingAPI implements IPixelStreamingAPI {

    rest: AxiosInstance;

    constructor(baseURL: string) {
        this.rest = axios.create({baseURL});
    }

    findPixelExperience(id: string): Promise<FindPixelExperienceResponse> {
        return this.rest.get(`/pixelExperiences/${id}`)
            .then(({data}) => new FindPixelExperienceResponse(data));
    }

    startPixelExperience(id: string): Promise<StartPixelExperienceResponse> {
        return this.rest.put(`/pixelExperiences/${id}/start`)
            .then(({data}) => <StartPixelExperienceResponse>data)
    }

}
